import Layout from "../components/layout";
import AlgoliaClient from '../utils/algoliaClient';
import { Configure, DynamicWidgets, Menu, RefinementList, HierarchicalMenu, Panel, InstantSearch, SortBy, MenuSelect } from "react-instantsearch-dom";
import JobHits from "../components/Jobs/jobHits";
import SearchInput from "../components/Search/searchInput";
import SvgText, { TextBackground } from "../components/SvgText/svgText";
import '../components/search.scss';

const App = () => {
  const nowTimestamp = Date.now();
  const nowInSeconds = Math.floor(nowTimestamp / 1000);
  return (
    <Layout pageTitle="RemoteInclusive - Inclusive and Transparent Job Listings - Search For the Perfect Next Position in Your Pay Range" description="RemoteInclusive - Inclusive and Transparent Job Listings - Search For the Perfect Next Position in Your Pay Range - Search for your next remote gig">
       <div className="search__search-header">
          <SvgText textBackground={TextBackground.AbstractBlue} id="1">
            <tspan x="0" dy="1em">Search for that Next Position!</tspan>
          </SvgText>
      </div>
      <div className="search__search-body">
        <InstantSearch
          indexName="jobs"
          searchClient={AlgoliaClient}
        >
        <Configure
          facets={['jobType']}
          // the highest value you could mount in "limit" and "showMoreLimit"
          maxValuesPerFacet={10}
          filters={`createDateSeconds <= ${nowInSeconds} AND expirationDateSeconds >= ${nowInSeconds}`}
        />
          <SearchInput />
          {/* <DynamicWidgets fallbackComponent={Menu}>
            <RefinementList attribute="brand" />
            <HierarchicalMenu attributes={['hierarchical.lvl0', 'hierarchical.lvl1']} />
            <Panel>
              <Menu attribute="category" />
            </Panel>
          </DynamicWidgets> */}
          <Panel>
            Sort 
            <SortBy
              defaultRefinement="jobs"
              items={[
                { value: 'jobs', label: 'Promoted' },
                { value: 'jobs_pay_range_end_desc', label: 'Salary Range Desc.' },
              ]}
            />
            Position Type 
            <MenuSelect
              attribute="jobType"
              />
          </Panel>
          {/* <RangeInput
            attribute={"payRangeStart"}
            // Optional parameters
            defaultRefinement={{ min: 40, max: 500 }}
            min={40}
            max={500}
            precision={10}
            translations={{
              submit: 'ok',
              separator: 'to',
            }}
          /> */}
          <JobHits />
        </InstantSearch>
      </div>
      <div className="page-layout__background">
        <div className="page-layout__bg-magenta" />
        <div className="page-layout__bg-blue" />
        <div className="page-layout__bg-purple" />
      </div>
    </Layout>
  )
}

export default App