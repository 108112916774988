import './searchInput.scss';
import { StateContext } from '../../utils/providers';
import { useContext } from 'react';
import { SearchBox } from 'react-instantsearch-dom';
import { SearchIcon, ClearIcon } from '@fluentui/react-icons-mdl2';

const SearchInput = () => {
  const state = useContext(StateContext);
  const currentSearchString: string | undefined = state.search && state.search.searchString ? state.search.searchString : undefined;

  return (
    <SearchBox
      className="search-input"
      translations={{
        submitTitle: 'Find your perfect match',
        resetTitle: 'Clear your search',
        placeholder: 'ex. Senior Software Engineer React Full Stack 150000',
      }}
      autoFocus={true}
    />

  )
};

export default SearchInput;
