import Job from "../../Entities/Job";
import JobPostSmall from './jobPostSmall';
import { Link } from 'gatsby';

export type JobListItemProps = {
  job: Job;
}

const JobListItem = ({job}: JobListItemProps) => {
  return (
    <Link to={job.jobUrl ? '/post/' + job.jobUrl : '/'} key={`job-post-${job.jobId}`} className='job-hit'>
      <JobPostSmall featured={false} job={job} />
    </Link>
  )
};

export default JobListItem