import { connectHits } from 'react-instantsearch-dom';
import JobListItem from './jobListItem';
import Job from '../../Entities/Job';

export type JobHitsProps = {
  hits: Array<Job>
};

const Hits = ({ hits }: JobHitsProps) => {
  return (
    <div className="job-search-results">      
      {hits && hits.map(hit => {
        return (
          <JobListItem job={hit} key={hit.jobId} />
        )
      })}
    </div>
  )
};

export default connectHits(Hits);